import { useState, useMemo } from 'react'

export default function useAppContext() {
  const [title, setTitle] = useState('Learn React')

  /**
   * The application memoizes in other words caches the context value.
   * Memoization keeps the context value object the same as long as provided value is the same,
   * preventing re-rendering of consumers every time the application re-renders.
   */
  const value = useMemo(() => ({ title, setTitle }), [title])

  return value
}
