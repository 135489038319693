import { degToRad } from '../../utils/math'

export default function HexagonBorder({ centerX, centerY, size, startDeg }) {
  let points = []

  let x = centerX + Math.cos(degToRad(startDeg)) * size
  let y = centerY + Math.sin(degToRad(startDeg)) * size
  points.push(`${x} ${y}`)

  x = centerX + Math.cos(degToRad(startDeg + 60)) * size
  y = centerY + Math.sin(degToRad(startDeg + 60)) * size
  points.push(`${x} ${y}`)

  x = centerX + Math.cos(degToRad(startDeg + 60)) * size * 0.93
  y = centerY + Math.sin(degToRad(startDeg + 60)) * size * 0.93
  points.push(`${x} ${y}`)

  x = centerX + Math.cos(degToRad(startDeg)) * size * 0.93
  y = centerY + Math.sin(degToRad(startDeg)) * size * 0.93
  points.push(`${x} ${y}`)

  return (
    <>
      <polygon points={points.join(', ')} fill={'rgba(50,50,50,50%)'} />
    </>
  )
}
