import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import InfoBubble from '../components/InfoBubble/InfoBubble'
import apiService from '../services/ApiService'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
const { get, patch } = apiService

export default function SetNickname() {
  const [users, setUsers] = useState([])
  const [nickname, setNickname] = useState('')
  const [redmineId, setRedmineId] = useState(0)
  const navigate = useNavigate()

  async function getUsers() {
    const { data } = await get('/redmine-users')
    setUsers(data.users)
  }

  useEffect(() => {
    getUsers()
  }, [])

  const saveDetails = async () => {
    if (redmineId === 0 || nickname === '') {
      return
    }

    const { raw } = await patch('/me', '', {
      redmine_id: redmineId,
      nickname: nickname
    })

    if (raw.status === 200) {
      setTimeout(navigate, 500, '/szabalyok')
    }
  }

  return (
    <div className="page page-setnickname">
      <Navbar />
      <div className="container mx-auto">
        <div className="flex flex-row flex-wrap py-4 justify-center">
          <main
            role="main"
            className="w-full pt-1 px-2 flex justify-center flex-col items-center max-w-sm md:max-w-lg px-4">
            <form className="w-full max-w-sm">
              <div className="flex items-center border-b border-red-500 py-2">
                <input
                  className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  type="text"
                  placeholder="Becenév"
                  aria-label="Becenév"
                  onChange={e => setNickname(e.target.value)}
                />
                <select
                  className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                  onChange={e => setRedmineId(e.target.value)}>
                  <option disabled defaultValue>
                    Neved
                  </option>
                  {users.map(e => (
                    <option value={e.id} key={e.id}>{`${e.lastname} ${e.firstname}`}</option>
                  ))}
                </select>
                <button
                  className="flex-shrink-0 bg-red-500 hover:bg-red-700 border-red-500 hover:border-red-700 text-sm border-4 text-white py-1 px-2 rounded"
                  type="button"
                  onClick={e => saveDetails()}>
                  Belépés
                </button>
              </div>
            </form>
            <InfoBubble
              title={'Már majdnem kész is vagy!'}
              description={'Válaszd ki a teljes neved és adj meg egy felhasználónevet, majd kattints a Belépés gombra!'}
            />
          </main>
        </div>
      </div>
      <Footer />
    </div>
  )
}
