import { Route, Routes } from 'react-router-dom'

import './App.scss'

import Login from 'pages/Login'
import PageNotFound from 'pages/PageNotFound'

import { AppContext } from 'context/AppContext'
import useAppContext from 'hooks/useAppContext'
import SetNickname from './pages/SetNickname'
import Rules from './pages/Rules'
import Game from './pages/Game'
import { useLocalStorage } from './hooks/localStorage'

function App() {
  const appState = useAppContext()

  const loggedIn = useLocalStorage().readStore('credentials') != null

  return (
    <AppContext.Provider value={appState}>
      <div className="app">
        <Routes>
          <Route path="/" element={loggedIn ? <Game /> : <Login />} />
          <Route path="/bejelentkezes" element={<Login />} />
          <Route path="/becenev" element={loggedIn ? <SetNickname /> : <Login />} />
          <Route path="/szabalyok" element={loggedIn ? <Rules /> : <Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </AppContext.Provider>
  )
}

export default App
