export const getNeightBours = index => {
  const y = Math.floor(index / 5)

  if (y % 2 === 1) {
    return [index - 10, index - 4, index + 6, index + 10, index + 5, index - 5]
  } else {
    return [index - 10, index - 5, index + 5, index + 10, index + 4, index - 6]
  }
}

export const isColorNear = (neightbours, color, colors) => {
  return neightbours.filter(index => (colors[index] ?? '') === color).length > 0
}
