import './Navbar.scss'

import logo from '../../assets/images/logo.png'
import { useLocalStorage } from '../../hooks/localStorage'
import { useNavigate } from 'react-router-dom'

//block py-2 px-3 text-white bg-red-700 rounded md:bg-transparent md:text-red-700 md:p-0 dark:text-white md:dark:text-red-500

export default function Navbar() {
  const loggedIn = useLocalStorage().readStore('credentials') != null
  const navigate = useNavigate()

  const clearCredentials = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useLocalStorage().store('credentials', null)
  }

  return (
    <nav className="bg-white border-gray-200 dark:bg-gray-900">
      <div className="max-w-screen-xxl flex flex-wrap items-center justify-between mx-auto p-4">
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={logo} alt="Logo" />
        </a>
        {loggedIn ? (
          <div className="w-full w-auto" id="navbar-default">
            <ul className="font-medium flex flex-row p-4 md:p-0 mt-4 border border-gray-100 rounded-lg bg-gray-50 md:space-x-8 rtl:space-x-reverse md:mt-0 md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
              <li>
                <a
                  href="/szabalyok"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0 dark:text-white md:dark:hover:text-red-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                  Játékszabály
                </a>
              </li>
              <li>
                <a
                  href="/profil"
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0 dark:text-white md:dark:hover:text-red-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                  Profil
                </a>
              </li>
              <li>
                <a
                  href="/logout"
                  onClick={e => {
                    e.preventDefault()
                    clearCredentials()
                    setTimeout(navigate, 500, '/bejelentkezes')
                  }}
                  className="block py-2 px-3 text-gray-900 rounded hover:bg-gray-100 md:hover:bg-transparent md:border-0 md:hover:text-red-700 md:p-0 dark:text-white md:dark:hover:text-red-500 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent">
                  Kilépés
                </a>
              </li>
            </ul>
          </div>
        ) : null}
      </div>
    </nav>
  )
}
