import './Hexagon.scss'
import { degToRad } from '../../utils/math'
import HexagonBorder from './HexagonBorder'
import { getNeightBours } from '../../utils/neighbour'
import base from '../../assets/images/base.svg'
import { isBase } from '../../utils/base'

export default function Hexagon({ x, y, index, color, click, colors }) {
  const size = 5

  let points = []

  let centerX = x * size * 3.15
  let centerY = y * size * 1.8

  if (y % 2 === 0) {
    centerX = centerX - size * 1.575
    centerY = centerY - size * 0.9
  }

  centerY = centerY - Math.floor((y + 1) / 2) * 9 + 5
  centerX = centerX + 6

  for (let i = 0; i < 6; i++) {
    const x = centerX + Math.cos(degToRad(i * 60)) * size
    const y = centerY + Math.sin(degToRad(i * 60)) * size

    points.push(`${x} ${y}`)
  }

  return (
    <>
      <polygon points={points.join(', ')} fill={color} onClick={() => click(index)} />

      {isBase(index, 4) ? (
        <image href={base} height={size} width={size} x={centerX - size / 2} y={centerY - size / 2} />
      ) : null}

      {getNeightBours(index).map((e, index) =>
        colors[e] !== color && color !== 'transparent' && color !== '#222' ? (
          <HexagonBorder size={size} centerX={centerX} centerY={centerY} startDeg={index * 60 - 120} key={index} />
        ) : null
      )}

      {/*<text*/}
      {/*  font-size="3"*/}
      {/*  fill="white"*/}
      {/*  font-family="Verdana"*/}
      {/*  text-anchor="middle"*/}
      {/*  alignment-baseline="middle"*/}
      {/*  x={centerX}*/}
      {/*  y={centerY}>*/}
      {/*  {index}*/}
      {/*</text>*/}
    </>
  )
}
