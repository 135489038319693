import { GoogleLogin } from '@react-oauth/google'
import { useState } from 'react'
import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import InfoBubble from '../components/InfoBubble/InfoBubble'
import apiService from '../services/ApiService'
import { useLocalStorage } from '../services/localStorage'
import { useNavigate } from 'react-router-dom'

export default function Login() {
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const { save, get } = apiService
  const { store } = useLocalStorage()
  const navigate = useNavigate()

  const responseMessage = async response => {
    setSuccess('Sikeres hitelesítés. Bejelentkezés folyamatban.')

    // const { data } = await save("/login", {
    //   "email": "dummyAdmin@example.com",
    //   "password": "AdminP@ssword1"
    // });
    const { data } = await save('/login/google', {
      token: response.credential
    })

    store('credentials', data)

    const resp = await get('/me')

    if (resp.data?.nickname == null) {
      setTimeout(navigate, 500, '/becenev')
    } else {
      setTimeout(navigate, 500, '/szabalyok')
    }
  }

  const errorMessage = error => {
    setError('Hiba a hitelesítés során.')
  }

  return (
    <div className="page page-login">
      <Navbar />
      <div className="container mx-auto">
        <div className="flex flex-row flex-wrap py-4 justify-center">
          <main
            role="main"
            className="w-full pt-1 px-2 flex justify-center flex-col items-center max-w-sm md:max-w-lg px-4">
            {error ? (
              <div
                className="p-4 mb-4 text-sm text-red-800 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400"
                role="alert">
                {error}
              </div>
            ) : null}
            {success ? (
              <div
                className="p-4 mb-4 text-sm text-green-800 rounded-lg bg-green-50 dark:bg-gray-800 dark:text-green-400"
                role="alert">
                {success}
              </div>
            ) : null}
            <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />

            <InfoBubble
              title={'Köszöntünk a Log&Go játékosai között!'}
              description={'Első lépésként kattints a Google belépés gombra, és lépj be a céges e-mail címeddel!'}
            />
          </main>
        </div>
      </div>
      <Footer />
    </div>
  )
}
