import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'

export default function Rules() {
  return (
    <div className="page page-rules">
      <Navbar />
      <div className="container mx-auto">
        <div className="flex flex-row flex-wrap py-4">
          <main role="main" className="w-full p-5 md:p-0">
            <div className="grid grid-cols-5 gap-3">
              <div className="col-span-5 sm:col-span-2">
                <div className="rounded-md bg-gray-300 pt-2">
                  <h2 className="text-lg font-bold mb-2">Elérhető játékok</h2>

                  <div className="grid grid-cols-5 py-4 px-2">
                    <div className="col-span-3 text-left px-0.5">November</div>
                    <div className="col-span-2 text-right">
                      <a
                        href="/"
                        className="text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-3 py-2 dark:bg-green-600 dark:hover:bg-green-700 focus:outline-none dark:focus:ring-green-800">
                        Játék
                      </a>
                    </div>
                  </div>

                  <h3 className="text-base mb-2 mt-2 px-2">Jelentkezz már most a következő havi játékokra!</h3>
                  <div className="grid grid-cols-5 py-4 px-2">
                    <div className="col-span-3 text-left px-0.5">December</div>
                    <div className="col-span-2 text-right">
                      <a
                        href="/"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                        Jelentkezés
                      </a>
                    </div>
                  </div>
                  <div className="grid grid-cols-5 py-4 px-2">
                    <div className="col-span-3 text-left px-0.5">Január</div>
                    <div className="col-span-2 text-right">
                      <a
                        href="/"
                        className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-3 py-2 dark:bg-red-600 dark:hover:bg-red-700 focus:outline-none dark:focus:ring-red-800">
                        Leiratkozás
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-span-5 sm:col-span-3">
                <div className="rounded-md bg-gray-300 text-left p-2">
                  <h2 className="text-lg font-bold mb-2">Szia! Üdvözlünk a Log&Go játékban!</h2>
                  <p>
                    Első lépésként kattints itt bal oldalt a részt veszek gombra. A hónap első napján aktiválódik a
                    játék, és indul a harc a legjobb logoló címért!
                    <br />
                    <br />
                    A játék lényege:
                    <br />
                    Minden munkanap válassz egy elfoglalni kívánt területet
                    <br />
                    Logold napközben az elvégzett munkáidat
                    <br />
                    A következő napon az előző napi logjaid alapján elfoglalhatod a kiválasztott területet, sőt akár
                    extra területre is szert tehetsz!
                    <br />
                    Ha elfogytak a szabad területek, indulhat a harc! Legyél jobb logoló, és szerezd meg az összes
                    területet!
                    <br />
                    <br />
                    További információkért nézd meg a részletes játékszabályzatot!
                    <br />
                    <br />
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  )
}
