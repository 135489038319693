import Navbar from '../components/Navbar/Navbar'
import Footer from '../components/Footer/Footer'
import PlayGround from '../components/Playground/PlayGround'
import { useEffect, useState } from 'react'
import apiService from '../services/ApiService'
const { get } = apiService

export default function Game() {
  const [details, setDetails] = useState([])

  async function getDetails() {
    const { data } = await get('/me')
    setDetails(data)
  }

  useEffect(() => {
    getDetails()
  }, [])

  return (
    <div className="page page-game">
      <Navbar />
      <div className="container mx-auto">
        <div className="flex flex-row flex-wrap py-4 justify-center">
          <main role="main" className="w-full p-5 md:p-0 max-w-screen-lg">
            <div className="grid grid-cols-12 gap-3 justify-center">
              <div className="col-span-12 sm:col-span-8">
                <PlayGround details={details} />
              </div>
              <div className="col-span-12 sm:col-span-4">
                <div className="rounded-md bg-gray-300 pt-2 pb-2 px-2 text-left">
                  <h2 className="text-lg font-bold mb-2">Szia {details.nickname}!</h2>
                  Itt a területfoglalás időszaka! Jelölj ki egy szabad területet, logolj és szerezd meg!
                </div>
                {/*<div className="rounded-md bg-gray-300 pt-2 pb-2 px-2 text-left mt-2">*/}
                {/*  Ranglista: <br />*/}
                {/*  1. <br />*/}
                {/*  2. <br />*/}
                {/*  3. <br />*/}
                {/*  4. <br />*/}
                {/*  5. <br />*/}
                {/*</div>*/}
              </div>
            </div>
          </main>
        </div>
      </div>
      <Footer />
    </div>
  )
}
