import Hexagon from '../Hexagon/Hexagon'
import { getNeightBours, isColorNear } from '../../utils/neighbour'
import apiService from '../../services/ApiService'
import { useEffect, useState } from 'react'
const { upload } = apiService

export default function PlayGround({ details }) {
  const [colors, setColors] = useState({})
  const [ownColor, setSetOwnColor] = useState({})
  const fields = 100

  const mapWidth = Math.sqrt(fields) / 2

  useEffect(() => {
    let newColors = {
      0: 'transparent',
      1: 'transparent',
      2: 'transparent',
      3: 'transparent',
      4: 'transparent',
      5: 'transparent',
      6: 'transparent',
      8: 'transparent',
      9: 'transparent',
      10: 'transparent',
      11: 'transparent',
      14: 'transparent',
      15: 'transparent',
      19: 'transparent',
      20: 'transparent',
      30: 'transparent',
      40: 'transparent',
      50: 'transparent',
      60: 'transparent',
      70: 'transparent',
      75: 'transparent',
      79: 'transparent',
      80: 'transparent',
      81: 'transparent',
      84: 'transparent',
      85: 'transparent',
      86: 'transparent',
      88: 'transparent',
      89: 'transparent',
      90: 'transparent',
      91: 'transparent',
      92: 'transparent',
      93: 'transparent',
      94: 'transparent',
      95: 'transparent',
      96: 'transparent',
      97: 'transparent',
      98: 'transparent',
      99: 'transparent'
    }

    function stringToColor(string) {
      string = string.toLowerCase()
      let hash = 0
      for (let i = 0; i < string.length; i++) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash)
      }
      let colour = '#'
      for (let i = 0; i < 3; i++) {
        let value = (hash >> (i * 8)) & 0xff
        colour += ('00' + value.toString(16)).substr(-2)
      }
      return colour
    }

    setSetOwnColor(stringToColor(details.email ?? ''))

    const setPlayerColors = playerDict => {
      const color = stringToColor(playerDict.email ?? '')

      ;(playerDict?.territories ?? []).forEach(territory => {
        newColors[territory.id] = color
      })
    }

    ;(details?.opponents ?? []).forEach(opponent => {
      setPlayerColors(opponent)
    })

    setPlayerColors(details)

    setColors({ ...newColors })
  }, [details])

  const teritoryClicked = async index => {
    try {
      if (isColorNear(getNeightBours(index), ownColor, colors) || true) {
        const { raw } = upload(`territory/${index}`, '')
        if (raw === 204) {
          let newColors = colors
          newColors[index] = '#555'
          setColors({ ...newColors })
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="home-page">
      <svg
        viewBox="0 0 75 82"
        xmlns="http://www.w3.org/2000/svg"
        style={{
          backgroundColor: '#444',
          borderRadius: '0.375rem'
        }}>
        {[...Array(fields).keys()].map((number, index) => (
          <Hexagon
            x={index % mapWidth}
            y={Math.floor(index / mapWidth)}
            index={index}
            color={colors[index] ?? '#222'}
            click={teritoryClicked}
            colors={colors}
            key={index}
          />
        ))}
      </svg>
    </div>
  )
}
