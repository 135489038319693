export const getBases = teamSize => {
  const teamSizes = {
    3: [7, 65, 69],
    4: [21, 24, 71, 74],
    5: [7, 25, 29, 71, 74]
  }

  return teamSizes[teamSize] ?? []
}

export const isBase = (index, teamSize) => {
  return getBases(teamSize).includes(index)
}
